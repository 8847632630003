import "./addMembership.scss";
import { Formik, Form } from "formik";
import { ADD_MEMBERSHIP_TO_BRAND } from "../../gqloperations/mutations";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import {
  frequency,
  membershipTypes,
  membershipLevels,
} from "../../constants/commonConstant";
import { format } from "date-fns";
import { getUTCdate } from "../../utils/getUTCdate";
import { addMembershipSchema } from "../../validations/MembershipValidation";
import { ToggleFormik, Input, Select, Button, Header } from "../../components";
import { LIST_MEMBERSHIPS } from "../../gqloperations/queries";
import { useMemberContext } from "../../contexts/MemberContext";
import { useNavigate } from "react-router-dom";
import { useHandleError } from '../../hooks/useHandleError';
const AddMembership = () => {
  const { sidebarBrandId, sidebarBrandName } = useMemberContext();
  const navigate = useNavigate();
  const [addMembership] = useMutation(ADD_MEMBERSHIP_TO_BRAND);
  const handleError = useHandleError();

  const handleCancel = () => {
    navigate(-1);
  };
  const getVariable = (values) => {
    let variables = {};
    variables.membershipInput = {
      ...values.membershipInput,
      brandId: sidebarBrandId,
    };
    variables.isPeak = values.isPeak;
    variables.paymentFrequency = values.paymentFrequency;
    if (values.paymentDelay) variables.paymentDelay = values.paymentDelay;
    if (values.contractLength) variables.contractLength = values.contractLength;
    variables.noticePeriod = values.noticePeriod;
    if (values.membershipType) variables.membershipType = values.membershipType;
    if (values.membershipLevel)
      variables.membershipLevel = values.membershipLevel;
    variables.membershipPaymentType = [
      {
        isActive: values.cash,
        name: "CASH",
      },
      {
        isActive: values.debitCard,
        name: "DEBIT",
      },
      {
        isActive: values.creditCard,
        name: "CREDIT",
      },
    ];
    variables.membershipActivationDate = values.membershipActivationDate
      .endDateTime
      ? {
          ...values.membershipActivationDate,
          startDateTime:
            values.membershipActivationDate.startDateTime + "T00:00:00Z",
          endDateTime:
            values.membershipActivationDate.endDateTime + "T23:59:59Z",
        }
      : {
          isActive: values.membershipActivationDate.isActive,
          startDateTime:
            values.membershipActivationDate.startDateTime + "T00:00:00Z",
        };
    // if (!variables.contractLength.amount || !variables.contractLength.type) {
    //   variables = {
    //     ...variables,
    //     contractLength: {
    //       amount: 0,
    //       type: "DAYS",
    //     },
    //   };
    // }
    // if (!variables.paymentDelay.amount || !variables.paymentDelay.type) {
    //   variables = {
    //     ...variables,
    //     paymentDelay: {
    //       amount: 0,
    //       type: "DAYS",
    //     },
    //   };
    // }

    return variables;
  };

  const onSubmit = async (values, OnSubmitProps) => {
    const { contractLength, paymentDelay, ...mandatoryValues } = values;

    let validValues = { ...mandatoryValues };
    if (contractLength.amount && contractLength.type) {
      validValues = { contractLength, ...validValues };
    }
    if (paymentDelay.amount && paymentDelay.type) {
      validValues = { paymentDelay, ...validValues };
    }

    try {
      await addMembership({
        variables: getVariable(validValues),
        onCompleted: () => {
          OnSubmitProps.setSubmitting(false);
          OnSubmitProps.resetForm();
          toast.success("Membership Created Successfully");
          navigate(-1);
        },
        refetchQueries: [
          {
            query: LIST_MEMBERSHIPS,
            variables: {
              filter: {
                and: [
                  { isDeleted: { ne: true } },
                  { brandId: { eq: `${sidebarBrandId}` } },
                ],
              },
            },
          },
        ],
      });
    } catch (error) {
      handleError(`${error}`);
    }
    OnSubmitProps.setSubmitting(false);
  };
  return (
    <Formik
      initialValues={{
        membershipType: "",
        membershipLevel: "",
        isPeak: false,
        membershipInput: {
          membershipName: "",
          costPrice: "",
          description: "",
          isActive: "",
          isLiveOnWebsite: "",
          joiningFee: "",
          joiningFee2: 0,
          joiningFee3: 0,
        },
        contractLength: {
          amount: "",
          type: "",
        },
        noticePeriod: {
          amount: 28,
          type: "DAYS",
        },
        paymentDelay: {
          amount: "",
          type: "",
        },
        paymentFrequency: {
          amount: "",
          type: "",
        },
        membershipActivationDate: {
          endDateTime: "",
          startDateTime: "",
          isActive: true,
        },
        creditCard: "",
        debitCard: "",
        cash: "",
      }}
      validationSchema={addMembershipSchema}
      onSubmit={(values, OnSubmitProps) => {
        onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <>
            <Header pageTitle="Add A Membership" />
            <Form>
              <div className="add-membership-page main mt-32 pd-18 mb-12">
                <div className="add-card mb-24">
                  <div className="membership-title-details flex-wrap">
                    <div className="inputs flex-wrap">
                      <div>
                        <h3 className="fs-12 text-gray">Brand</h3>
                        <p className="fs-12">{sidebarBrandName}</p>
                      </div>
                      <Input
                        type="text"
                        label="Membership Name *"
                        name="membershipInput.membershipName"
                        autoComplete="off"
                        className="fs-12"
                      />
                      <Input
                        type="number"
                        label="Cost Price *"
                        name="membershipInput.costPrice"
                        className="fs-12"
                        autoComplete="off"
                      />
                    </div>
                    <ToggleFormik
                      label="Membership Active"
                      name="membershipInput.isActive"
                      className="fs-10"
                      value={formik.isActive}
                    />
                  </div>
                  <div className="membership-wrap">
                    <Select
                      label="Membership Type *"
                      optionvalues={membershipTypes}
                      selecttype="array"
                      name="membershipType"
                      // id="membershipType"
                      autoComplete="off"
                      className="select-element-value"
                    />
                    <Select
                      label="Membership Level *"
                      optionvalues={membershipLevels}
                      selecttype="array"
                      name="membershipLevel"
                      autoComplete="off"
                      className="select-element-value"
                    />
                  </div>
                </div>
                <div className="desc-row">
                  <h3 className="fs-12">Description *</h3>
                  <textarea
                    className="reason-box fs-12"
                    id="description"
                    name="membershipInput.description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.membershipInput.description}
                  ></textarea>
                  {formik?.errors?.membershipInput?.description &&
                  formik?.touched?.membershipInput?.description ? (
                    <div className="error fs-12">
                      {formik.errors.membershipInput.description}
                    </div>
                  ) : null}
                </div>
                <div className="membership-settings-grid mt-32 ">
                  <div className="pay-freq input-select-title">
                    <p className="fs-12">Payment Frequency *</p>
                    <div className="input-select">
                      <div className="box-input-custom-error">
                        <Input
                          type="number"
                          label=""
                          name="paymentFrequency.amount"
                          className="fs-12 "
                          autoComplete="off"
                        />
                      </div>

                      <Select
                        optionvalues={frequency}
                        selecttype="array"
                        name="paymentFrequency.type"
                        autoComplete="off"
                        className="select-element-value"
                      />
                    </div>
                  </div>
                  <div className="pay-delay input-select-title">
                    <p className="fs-12">Payment Delay</p>
                    <div className="input-select">
                      <div className="box-input-custom-error">
                        <Input
                          type="number"
                          label=""
                          name="paymentDelay.amount"
                          className="fs-12"
                          autoComplete="off"
                        />
                      </div>
                      <Select
                        optionvalues={frequency}
                        selecttype="array"
                        name="paymentDelay.type"
                        autoComplete="off"
                        className="select-element-value"
                      />
                    </div>
                  </div>
                  <div className="contract-length input-select-title">
                    <p className="fs-12"> Contract Length </p>
                    <div className="input-select">
                      <div className="box-input-custom-error">
                        <Input
                          type="number"
                          label=""
                          name="contractLength.amount"
                          className="fs-12"
                          autoComplete="off"
                        />
                      </div>
                      <Select
                        optionvalues={frequency}
                        selecttype="array"
                        name="contractLength.type"
                        autoComplete="off"
                        className="select-element-value"
                      />
                    </div>
                  </div>
                  <div></div>
                  <div className="notice-period input-select-title">
                    <p className="fs-12"> Notice Period * </p>
                    <div className="input-select">
                      <Input
                        type="number"
                        label=""
                        name="noticePeriod.amount"
                        className="fs-12"
                        autoComplete="off"
                      />
                      <Select
                        optionvalues={frequency}
                        selecttype="array"
                        name="noticePeriod.type"
                        autoComplete="off"
                        className="select-element-value"
                      />
                    </div>
                  </div>
                  <div className="pay-type">
                    <p className="fs-12"> Payment Type </p>
                    <ToggleFormik
                      label="Direct Debit"
                      name="debitCard"
                      className="fs-10"
                      value={formik.debitCard}
                    />
                    <ToggleFormik
                      label="Credit Card"
                      name="creditCard"
                      className="fs-10"
                      value={formik.creditCard}
                    />
                    <ToggleFormik
                      label="Cash"
                      name="cash"
                      className="fs-10"
                      value={formik.cash}
                    />
                  </div>
                  <div className="join-fee-1">
                    <Input
                      type="number"
                      label="Joining Fee *"
                      name="membershipInput.joiningFee"
                      autoComplete="off"
                      className="fs-12"
                    />
                  </div>
                  <div className="join-fee-2">
                    <Input
                      type="number"
                      label="Activation Fee"
                      name="membershipInput.joiningFee2"
                      autoComplete="off"
                      className="fs-12"
                    />
                  </div>
                  <div className="join-fee-3">
                    <Input
                      type="number"
                      label="Fob/Pass Fee"
                      name="membershipInput.joiningFee3"
                      autoComplete="off"
                      className="fs-12"
                    />
                  </div>
                  <div>
                    <div className="website-live">
                      <ToggleFormik
                        label="LIVE ON WEBSITE"
                        name="membershipInput.isLiveOnWebsite"
                        className="fs-12"
                        value={formik.isLiveOnWebsite}
                      />
                    </div>

                    <div className="is-peak">
                      <ToggleFormik
                        label="PEAK HOURS"
                        name="isPeak"
                        className="fs-12"
                        value={formik.isPeak}
                      />
                    </div>
                  </div>
                  <div className="start-date">
                    {" "}
                    <Input
                      type="date"
                      label="Start Date *"
                      name="membershipActivationDate.startDateTime"
                      className="fs-12"
                      autoComplete="off"
                      min={format(new Date(), "yyyy-MM-dd")}
                      max={
                        formik?.values?.membershipActivationDate?.endDateTime
                          ? formik.values.membershipActivationDate.endDateTime
                          : ""
                      }
                    />
                  </div>
                  <div className="end-date">
                    {" "}
                    <Input
                      type="date"
                      label="End Date"
                      name="membershipActivationDate.endDateTime"
                      className="fs-12"
                      autoComplete="off"
                      min={formik.values.membershipActivationDate.startDateTime}
                    />
                  </div>
                </div>
              </div>
              <div className="right-btn add-membership pb-24">
                <Button
                  loading={formik.isSubmitting}
                  name={"Save"}
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-large btn-confirm  fs-12"
                  disabled={formik.isSubmitting ? true : false}
                />
                <Button
                  name="Cancel"
                  btntype="reset"
                  btnname="submit"
                  className="btn btn-large btn-deny fs-12"
                  click={handleCancel}
                />
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default AddMembership;
