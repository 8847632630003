import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Checkbox } from "../../../../components";
import { useAddMemberContext } from "../../../../contexts/AddMemberContext";
import "./payment.scss";
import { NavigateButtonGroup } from "../index";
import { toast } from "react-toastify";
import SingupModalPayment from "../../../../components/modal/SignupModalPayment";
import { useMutation } from "@apollo/client";
import { useHandleError } from "../../../../hooks/useHandleError";
import {
  SINGUP_MEMBER_OVER_18,
  SINGUP_MEMBER_UNDER_18,
} from "../../../../gqloperations/mutations";
import LoadingModalSignup from "../../../../components/modal/LoadingModalSignup";
import { format } from "date-fns";
function Payment() {
  const { handleMoveStepNext, memberInformation, memberData } =
    useAddMemberContext();
  const [singupMemberOver18, { loading: singupMemberOver18Loading }] =
    useMutation(SINGUP_MEMBER_OVER_18);
  const [singupMemberUnder18, { loading: singupMemberUnder18Loading }] =
    useMutation(SINGUP_MEMBER_UNDER_18);
  const [isAccept, setIsAccept] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [extendedLoading, setExtendedLoading] = useState(false);
  const [loadingText, setLoadingText] = useState({ header: "", content: "" });
  const [successText, setSuccessText] = useState({ header: "", content: "" });
  const handleError = useHandleError();
  const voucherType = memberInformation?.voucherDetail?.type;
  const isVoucherNoPayment =
    voucherType === "COMPLIMENTARY" &&
    !memberInformation?.yourMembershipData.joiningFee &&
    !memberInformation?.yourMembershipData.activationFee &&
    !memberInformation?.yourMembershipData.accessFee;
  const isPaymentDelay =
    memberInformation?.membershipDetails?.membershipDetails?.paymentDelay
      ?.amount !== 0 &&
    memberInformation?.membershipDetails.membershipDetails?.paymentDelay !==
      null;
  const isVoucherDelay =
    Object.keys(memberInformation?.voucherDetail || {}).length > 0 &&
    memberInformation?.voucherDetail?.paymentDelay !== null &&
    memberInformation?.voucherDetail?.paymentDelay?.amount !== 0;
  const zeroCostMembership =
    !memberInformation.yourMembershipData?.membershipPrice &&
    !memberInformation?.yourMembershipData?.joiningFee &&
    !memberInformation?.yourMembershipData?.activationFee &&
    !memberInformation?.yourMembershipData?.accessFee &&
    !isPaymentDelay &&
    !isVoucherDelay;

  useEffect(() => {
    if (singupMemberOver18Loading || singupMemberUnder18Loading) {
      setExtendedLoading(true);
    } else {
      setTimeout(() => {
        setExtendedLoading(false);
      }, 3000);
    }
  }, [singupMemberOver18Loading, singupMemberUnder18Loading]);

  const handleLoadingText = () => {
    const getLoadingTextHeader = (
      age,
      recurring,
      isVoucherNoPayment,
      zeroCostMembership
    ) => {
      if (isVoucherNoPayment || !recurring || zeroCostMembership) {
        return "Signing Up";
      } else if (age < 18) {
        return "Saving your payment method";
      } else {
        return "Processing your payment and signing up";
      }
    };

    const getSuccessTextHeader = (
      age,
      recurring,
      isVoucherNoPayment,
      zeroCostMembership
    ) => {
      if (isVoucherNoPayment || !recurring || zeroCostMembership) {
        return "Sign up Successful";
      } else {
        return "Payment Successful";
      }
    };

    const getLoadingTextContent = (
      age,
      recurring,
      isVoucherNoPayment,
      zeroCostMembership
    ) => {
      if (age < 18 && !recurring) {
        return " Processing time may take up to 60 seconds";
      } else if (age < 18 && !isVoucherNoPayment && !zeroCostMembership) {
        return "We securely save your payment method to process your payment once your membership has been approved by your parent/guardian.";
      } else {
        return " Processing time may take up to 60 seconds";
      }
    };

    const getSuccessTextContent = (
      age,
      recurring,
      isVoucherNoPayment,
      zeroCostMembership
    ) => {
      if (age < 18 && !recurring) {
        return "Please check with your parent/guardian to access their email and approve the consent form.";
      } else if (age >= 18 && !recurring) {
        return "Thank you! Your payment has been processed; please check your email to complete your signup.";
      } else if (age >= 18 && !isVoucherNoPayment && !zeroCostMembership) {
        return "Thank you! Your payment has been processed; please check your email to complete your signup.";
      } else if (age < 18 && !isVoucherNoPayment && !zeroCostMembership) {
        return "Please check with your parent/guardian to access their email and approve the consent form.";
      } else {
        return "Thank you! Please check your email to complete your signup.";
      }
    };

    const recurringMembership =
      memberInformation?.membershipDetails?.membershipDetails?.recurring;
    const loadingTextHeader = getLoadingTextHeader(
      memberInformation.age,
      recurringMembership,
      isVoucherNoPayment,
      zeroCostMembership
    );
    const successTextHeader = getSuccessTextHeader(
      memberInformation.age,
      recurringMembership,
      isVoucherNoPayment,
      zeroCostMembership
    );
    const loadingTextContent = getLoadingTextContent(
      memberInformation.age,
      recurringMembership,
      isVoucherNoPayment,
      zeroCostMembership
    );
    const successTextContent = getSuccessTextContent(
      memberInformation.age,
      recurringMembership,
      isVoucherNoPayment,
      zeroCostMembership
    );

    setLoadingText({ header: loadingTextHeader, content: loadingTextContent });
    setSuccessText({ header: successTextHeader, content: successTextContent });
  };
  const signupMember = async (payload) => {
    handleLoadingText();
    if (memberInformation.age >= 18) {
      await singupMemberOver18({
        variables: {
          input: {
            ...payload,
          },
        },
        onCompleted: (data) => {
          if (data.signupMemberViaWebOver18?.memberId) {
            setTimeout(() => {
              setIsOpenModal(false);
              handleMoveStepNext();
            }, 3000);
          } else {
            setExtendedLoading(false);
            handleError(data.signupMemberViaWebOver18?.message);
          }
        },
        onError: (error) => {
          setExtendedLoading(false);

          handleError(`${error}`);
        },
      });
    } else {
      await singupMemberUnder18({
        variables: {
          input: {
            ...payload,
          },
        },
        onCompleted: (data) => {
          if (data.signupMemberViaWebUnder18?.memberId) {
            setTimeout(() => {
              setIsOpenModal(false);
              handleMoveStepNext();
            }, 3000);
          } else {
            setExtendedLoading(false);
            handleError(data.signupMemberViaWebUnder18?.message);
          }
        },
        onError: (error) => {
          setExtendedLoading(false);
          handleError(`${error}`);
        },
      });
    }
  };

  const handleShowFuturePayment = () => {
    const isComplimentary =
      memberInformation.yourMembershipData.membershipPrice +
        memberInformation.yourMembershipData.joiningFee +
        memberInformation.yourMembershipData.activationFee +
        memberInformation.yourMembershipData.accessFee ===
        0 &&
      !isPaymentDelay &&
      !isVoucherDelay;
    const isFitnessPassport =
      memberInformation?.membershipDetails?.membershipDetails
        ?.membershipType === "FITNESS_PASSPORT";
    const isPif =
      memberInformation?.membershipDetails?.membershipDetails?.recurring ===
      false;

    if (isComplimentary) {
      return false;
    } else if (isFitnessPassport) {
      return false;
    } else if (isPif) {
      return false;
    } else return true;
  };

  return (
    <div>
      {extendedLoading && (
        <LoadingModalSignup>
          <div>
            {singupMemberOver18Loading || singupMemberUnder18Loading ? (
              <div class="spinner"></div>
            ) : (
              <div class="tick">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle
                    class="tick-circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    class="tick-check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
            )}
            <div>
              {singupMemberOver18Loading || singupMemberUnder18Loading ? (
                <div className="header">{loadingText.header}</div>
              ) : (
                <div className="header">{successText.header}</div>
              )}
              {singupMemberOver18Loading || singupMemberUnder18Loading ? (
                <div className="content"> {loadingText.content}</div>
              ) : (
                <div className="content">{successText.content}</div>
              )}
            </div>
          </div>
        </LoadingModalSignup>
      )}

      <Formik
        enableReinitialize
        initialValues={{}}
        onSubmit={async (values, OnSubmitProps) => {
          if (isAccept) {
            if (isVoucherNoPayment || zeroCostMembership) {
              signupMember({
                membershipStartDate: format(
                  new Date(memberData.membershipStartDate),
                  "yyyy-MM-dd"
                ),
                prospectId: memberInformation?.signupData.prospectId,
              });
            } else {
              setIsOpenModal(true);
            }
          } else {
            handleError(`Please accept Terms and Conditions`);
          }
        }}
      >
        {(formik) => {
          return (
            <Form id="user-details-form">
              <div className="user-details-form">
                <p>PAYMENT DETAILS</p>
                <div className="">
                  <h3 className="term-header">TERMS AND CONDITION*</h3>
                  <p className="term-content">
                    <span>If you have chosen to pay by Direct Debit.</span> By
                    acknowledging and submitting this Agreement, you authorise
                    us to debit all Membership Fees from the nominated credit
                    card via instalments in advance. The Agreement outlines the
                    applicable Terms and Conditions to your direct debit
                    request. You agree to these terms and conditions unless you
                    notify us otherwise before the first direct debit day. If
                    you would like to pay via your bank account, you can add
                    this within the Member Portal once you have joined.
                  </p>
                  <p className="term-content">
                    <span>
                      If you have chosen to pay your membership upfront and in
                      full,
                    </span>{" "}
                    you authorise us to debit/ charge all Membership Fees
                    upfront and in advance from the nominated credit card upon
                    submitting your membership application.
                  </p>
                  <div className="checkbox-agree">
                    <div className="">
                      <Checkbox
                        onChange={(e) => setIsAccept(e.target.checked)}
                      />
                    </div>
                    <p>
                      The Agreement outlines the applicable Terms and Conditions
                      to your payment request. <br />
                      You agree to these terms and conditions.
                    </p>
                  </div>
                </div>
              </div>

              <NavigateButtonGroup />
            </Form>
          );
        }}
      </Formik>
      {isOpenModal && (
        <SingupModalPayment
          cardRequestId={memberInformation?.signupData?.tokenId}
          setShowModalPayment={setIsOpenModal}
          onCompleted={signupMember}
          memberData={memberData}
          signupData={memberInformation}
          amount={
            memberInformation.yourMembershipData.membershipPrice +
            memberInformation.yourMembershipData.joiningFee +
            memberInformation.yourMembershipData.activationFee +
            memberInformation.yourMembershipData.accessFee
          }
          showFuturePayment={handleShowFuturePayment()}
        />
      )}
    </div>
  );
}

export default Payment;
