import { FaRegCircleCheck } from "react-icons/fa6";
import "../addMembership.scss";
import "./membershipList.scss";
import { Button } from "../../../components";
import { useState } from "react";
import {
  GET_LOCTAION_BY_ID,
  GET_VOUCHER_BY_CODE,
} from "../../../gqloperations/queries";
import { useMemberContext } from "../../../contexts/MemberContext";
import { useLazyQuery, useQuery } from "@apollo/client";
import { addDays, format, isAfter, isBefore } from "date-fns";
import { useHandleError } from "../../../hooks/useHandleError";
import checkDateOverlap from "../../../utils/checkDateOverlap";
const MembershipList = (props) => {
  const {
    membershipList,
    handleMembershipType,
    membershipId,
    voucherDetail,
    setVoucherDetail,
    voucherCode,
    setVoucherCode,
    memberDetails,
  } = props;
  const [isRecurring, setIsRecurring] = useState(true);
  const { sidebarLocationId } = useMemberContext();
  const [error, setError] = useState("");
  const handleError = useHandleError();
  const [currentMaintenance, setCurrentMaintenance] = useState({});
  const previousMember = memberDetails.contracts.items.every((contract) => {
    const expiryDate = contract?.expiryDateTime;
    const endDate = contract.endDateTime;
    const today = new Date().toISOString(); // today is in UTC format

    if (expiryDate) {
      // If expiryDate exists, only check expiryDate
      return expiryDate <= today;
    } else if (endDate) {
      // If expiryDate does not exist, check endDate
      return endDate < today;
    } else {
      // Return false if neither expiryDate nor endDate exists
      return false;
    }
  });

  const [getVoucherByCode, { loading: getVoucherByCodeLoading }] =
    useLazyQuery(GET_VOUCHER_BY_CODE);

  const { data: locationDetail } = useQuery(GET_LOCTAION_BY_ID, {
    fetchPolicy: "no-cache",
    variables: {
      id: sidebarLocationId,
    },
    onCompleted: (data) => {
      const maintenanceDates =
        data?.getLocation?.locationMaintenance?.items.filter(
          (maintenance) => !maintenance.isDeleted
        );
      setCurrentMaintenance(
        maintenanceDates?.find(({ startDateTime, endDateTime }) => {
          return checkDateOverlap(new Date(), {
            start: startDateTime,
            end: endDateTime,
          });
        })
      );
    },
  });

  const handleVoucherError = (errorMessage) => {
    setError(errorMessage);
    handleError(errorMessage);
    setVoucherDetail({});
  };

  const checkVoucherCode = (voucherCode) => {
    getVoucherByCode({
      fetchPolicy: "no-cache",
      variables: {
        voucherCode: voucherCode,
      },
      onCompleted: (data) => {
        if (data?.getVoucherByVoucherCode.items.length) {
          const voucher = data.getVoucherByVoucherCode.items[0];
          const voucherLocation = voucher.voucherLocation.items.filter(
            (item) => item.locationId === sidebarLocationId
          );
          const isVoucherDelay =
            voucher?.paymentDelay?.amount !== 0 &&
            Object.keys(voucher?.paymentDelay || {}).length;

          if (
            (voucher?.type === "COMPLIMENTARY" && !previousMember) ||
            voucher.memberType === "NEW"
          ) {
            handleVoucherError(
              "This voucher is not valid for existing member!"
            );
          } else if (!voucherLocation.length) {
            handleVoucherError("This voucher is not valid for this location!");
          } else if (voucher.used >= voucher.quantity) {
            handleVoucherError("Voucher limit exceeded!");
          } else if (
            isAfter(new Date(), new Date(voucher.endDateTime)) &&
            voucher?.endDateTime
          ) {
            handleVoucherError("This voucher has expired!");
          } else if (isBefore(new Date(), new Date(voucher.startDateTime))) {
            handleVoucherError(
              `You cannot use this voucher before ${new Date(
                voucher.startDateTime
              ).toLocaleDateString("en-GB")}!`
            );
          } else if (
            !membershipList.some(
              (item) =>
                item?.membershipDetails.id === voucher.membershipDetail.id
            )
          ) {
            handleVoucherError("This voucher is not valid for this member!");
          } else if (isVoucherDelay && !previousMember) {
            handleVoucherError(
              "Cannot apply payment delay voucher to existing members!"
            );
          } else {
            if (!voucher.isActive) {
              handleVoucherError("This voucher is not active!");
            } else {
              setVoucherDetail(voucher);
              handleMembershipType(voucher.membershipDetail?.id);
              setError("");
            }
          }
        } else {
          setVoucherDetail({});
          handleVoucherError("This voucher does not exist!");
        }
      },
    });
  };
  return (
    <div className="membership-list-container">
      <div className="header-container">
        <div>
          <p className="membership-step-title">
            MEMBERSHIP OPTION FOR
            <br />
            <span>PLUS FITNESS {locationDetail?.getLocation?.name} </span>
            <br />
            {Object.keys(currentMaintenance || {}).length > 0 && (
              <div>
                <span className="location-maintenance">
                  {" "}
                  {`This location is currently undergoing maintenance from ${format(
                    new Date(currentMaintenance.startDateTime),
                    "dd/MM/yyyy"
                  )} to ${format(
                    new Date(currentMaintenance.endDateTime),
                    "dd/MM/yyyy"
                  )}. You can join now, but the club will not open until ${format(
                    addDays(new Date(currentMaintenance.endDateTime), 1),
                    "MMMM d, yyyy"
                  )}`}
                </span>
                <br />
              </div>
            )}
            <span>DO YOU HAVE A PROMO CODE?</span>
          </p>
          <div className="promoCodeInputs">
            <input
              maxLength={8}
              type="name"
              label="name"
              name="voucherCode"
              id="name"
              value={voucherCode}
              autoComplete="off"
              onChange={(e) => {
                setVoucherCode(e.target.value);
                if (!e.target.value) {
                  setVoucherDetail({});
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            />
            <Button
              className="btn btn-transparent btn-small"
              name="apply"
              btnname="button"
              btntype="button"
              loading={getVoucherByCodeLoading}
              click={() => checkVoucherCode(voucherCode)}
            ></Button>
          </div>{" "}
        </div>
        <div className="membership-type-container">
          <Button
            click={() => setIsRecurring(true)}
            name="FORTNIGHTLY"
            btntype="button"
            className={`membership-type-button ${isRecurring && "active"}`}
          />{" "}
          <Button
            click={() => setIsRecurring(false)}
            name="PAID IN FULL"
            btntype="button"
            className={`membership-type-button ${!isRecurring && "active"}`}
          />{" "}
        </div>
      </div>
      {Object.keys(voucherDetail || {}).length && !error ? (
        <div className="voucher-card">
          <div className="code-applied"> PROMO CODE APPLIED</div>
          <div className="membership-content">
            {voucherDetail.type === "PERCENT" ||
            voucherDetail.type === "FIXED" ? (
              <p>{voucherDetail?.membershipDetail?.membershipName}</p>
            ) : (
              <span className="complimentary-header">
                PF-
                <span className="">
                  {voucherDetail.type === "VISIT_PASS"
                    ? "VISIT"
                    : "COMPLIMENTARY"}
                </span>
              </span>
            )}
            {voucherDetail?.type !== "COMPLIMENTARY" && (
              <p>
                $
                {voucherDetail?.membershipDetail?.recurring
                  ? (voucherDetail.type === "PERCENT"
                      ? voucherDetail?.membershipDetail?.costPrice -
                        voucherDetail?.membershipDetail?.costPrice *
                          (voucherDetail?.cost * 0.01)
                      : voucherDetail?.cost) /
                    voucherDetail?.membershipDetail?.paymentFrequency.amount?.toFixed(
                      2
                    )
                  : voucherDetail?.cost?.toFixed(2)}
                {/* period next to the price can consider make it function if needed */}
                {voucherDetail?.membershipDetail?.recurring && (
                  <span>
                    /{voucherDetail?.membershipDetail?.paymentFrequency.type}
                  </span>
                )}
              </p>
            )}

            {/* gray line under big price */}
            {voucherDetail?.type !== "COMPLIMENTARY" && (
              <div>
                {voucherDetail?.membershipDetail?.recurring ? (
                  <p className="price-sum">
                    {`$${
                      voucherDetail.type === "PERCENT"
                        ? voucherDetail?.membershipDetail?.costPrice -
                          voucherDetail?.membershipDetail?.costPrice *
                            (voucherDetail?.cost * 0.01)
                        : voucherDetail?.cost
                    } fortnightly Direct Debit`}
                  </p>
                ) : (
                  <p className="price-sum">SINGLE PAYMENT</p>
                )}
              </div>
            )}

            <div className="description">
              {(voucherDetail?.type === "FIXED" ||
                voucherDetail?.type === "PERCENT") &&
                [
                  ...(voucherDetail?.membershipDetail?.description.includes(
                    "All Plus Fitness 24/7"
                  )
                    ? ['"""All Plus Fitness 24/7']
                    : [""]),
                  ...(voucherDetail?.membershipDetail?.description.includes(
                    "Eligibility"
                  )
                    ? ["Eligibility: N/A"]
                    : [""]),
                  `$${voucherDetail?.joiningFee} Joining fee`,

                  `$${voucherDetail?.activationFee} Activation fee`,

                  `$${voucherDetail?.passFee} Pass fee`,
                ].map(
                  (i, index) =>
                    i && (
                      <div key={index}>
                        <FaRegCircleCheck />
                        <p>{i}</p>
                      </div>
                    )
                )}
              {voucherDetail?.type === "COMPLIMENTARY" &&
                [
                  ...voucherDetail?.note
                    .split(",")
                    .concat([
                      ...(voucherDetail.type === "VISIT_PASS"
                        ? [`For ${voucherDetail.visit} visits`]
                        : [`For ${voucherDetail.duration} days`]),
                      `$${voucherDetail?.joiningFee} Joining fee`,
                      `$${voucherDetail?.activationFee} Activation fee`,
                      `$${voucherDetail?.passFee} Pass fee`,
                    ]),
                ].map(
                  (i, index) =>
                    i && (
                      <div key={index}>
                        <FaRegCircleCheck />
                        <p>{i}</p>
                      </div>
                    )
                )}
            </div>
          </div>
          <Button
            btntype="button"
            name="Choose Plan"
            className={`select-membership active btn btn-transparent btn-lg`}
          ></Button>
        </div>
      ) : (
        <div className="membership-list">
          {membershipList
            .filter((i) => i.membershipDetails.recurring === isRecurring)
            .map((i, index) => {
              let finalFees = { joiningFee: 0, activationFee: 0, fobFee: 0 };

              // if 3 of the fee belong to the location is === null (which happened in old very old membership location)
              // we will populate the price from the membership instead of the price in the location
              if (
                i.joiningFee === null &&
                i.activationFee === null &&
                i.fobFee === null
              ) {
                finalFees = {
                  joiningFee: i.membershipDetails.joiningFee,
                  activationFee: i.membershipDetails.joiningFee2,
                  fobFee: i.membershipDetails.joiningFee3,
                };
              } else {
                finalFees = {
                  joiningFee: i.joiningFee,
                  activationFee: i.activationFee,
                  fobFee: i.fobFee,
                };
              }
              return (
                <div key={i.id}>
                  <div>
                    <p>{i.membershipDetails.membershipName}</p>

                    {/* big price in white */}
                    <p>
                      $
                      {isRecurring
                        ? (i.costPrice / 2)?.toFixed(2)
                        : i.costPrice?.toFixed(2)}
                      {/* period next to the price can consider make it function if needed */}
                      {isRecurring && (
                        <span>
                          {" "}
                          /
                          {i.membershipDetails.paymentFrequency?.type?.charAt(
                            i.membershipDetails?.paymentFrequency?.type
                              ?.length - 1
                          ) === "s"
                            ? i.membershipDetails?.paymentFrequency?.type?.slice(
                                0,
                                -1
                              )
                            : i.membershipDetails?.paymentFrequency?.type?.slice(
                                0,
                                -1
                              )}
                        </span>
                      )}
                    </p>

                    {/* gray line under big price */}
                    {isRecurring ? (
                      <p className="price-sum">
                        ${i.costPrice.toFixed(2) + " fortnightly Direct Debit"}
                      </p>
                    ) : (
                      <p className="price-sum">SINGLE PAYMENT</p>
                    )}
                    <div className="description">
                      {[
                        ...i.membershipDetails.description?.split(","),
                        `Joining fee: $${
                          finalFees.joiningFee?.toFixed(2) ?? "0.00"
                        }`,
                        `Activation fee: $${
                          finalFees.activationFee?.toFixed(2) ?? "0.00"
                        }`,
                        `Access fee: $${
                          finalFees.fobFee?.toFixed(2) ?? "0.00"
                        }`,
                      ].map((i, index) => (
                        <div key={index}>
                          <FaRegCircleCheck />
                          <p>{i}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <Button
                    click={(e) => {
                      handleMembershipType(i.membershipDetails?.id);
                    }}
                    btntype="button"
                    name="Choose Plan"
                    className={`select-membership btn btn-transparent btn-lg ${
                      i.membershipDetails?.id === membershipId && "active"
                    }`}
                  ></Button>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default MembershipList;
